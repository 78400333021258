registerProcessor(
  'ADSREnvelopeProcessor',
  class ADSREnvelopeProcessor extends AudioWorkletProcessor {
    static get parameterDescriptors() {
      return [
        {
          name: 'clock',
          defaultValue: 0,
          minValue: -1,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'attack',
          defaultValue: 0.001,
          minValue: 0.001,
          maxValue: 60,
          automationRate: 'k-rate',
        },
        {
          name: 'decay',
          defaultValue: 0.5,
          minValue: 0.001,
          maxValue: 60,
          automationRate: 'k-rate',
        },
        {
          name: 'sustain',
          defaultValue: 0.5,
          minValue: 0,
          maxValue: 1,
          automationRate: 'k-rate',
        },
        {
          name: 'release',
          defaultValue: 0.5,
          minValue: 0.001,
          maxValue: 60,
          automationRate: 'k-rate',
        },
      ];
    }

    lastClock = 0;
    value = 0;
    rising = false;

    process(
      _: Float32Array[][],
      [[output]]: Float32Array[][],
      {
        clock: [clock],
        attack: [attack],
        decay: [decay],
        sustain: [sustain],
        release: [release],
      }: { [param: string]: Float32Array },
    ): boolean {
      if (this.lastClock <= 0 && clock > 0) {
        this.rising = true;
        this.port.postMessage(['trigger']);
      }
      this.lastClock = clock;

      // cut off processing when envelope is not looping and cycle is complete
      if (!this.rising && this.value <= 0) return true;

      let transitionIndex = 0;
      let index = 0;
      let delta;
      while (transitionIndex < output.length) {
        if (this.rising) {
          delta = 1 / attack / sampleRate;
          transitionIndex += Math.ceil((1 - this.value) / delta);
          if (transitionIndex < output.length) this.rising = false;
        } else if (clock > 0 && this.value > sustain) {
          delta = -1 / decay / sampleRate;
          transitionIndex += Math.ceil((this.value - sustain) / -delta);
        } else if (clock > 0) {
          output.fill(sustain, transitionIndex);
          break;
        } else if (this.value >= 0) {
          delta = -1 / release / sampleRate;
          transitionIndex += Math.ceil(this.value / -delta);
        } else {
          break;
        }

        for (
          let end = Math.min(transitionIndex, output.length);
          index < end;
          index++
        ) {
          output[index] = this.value;
          this.value += delta;
        }
      }

      return true;
    }
  },
);
